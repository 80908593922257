const menuItems = {
  items: [
    {
      id: 'admin',
      title: 'Admin',
      type: 'group',
      icon: 'icon-support',
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          url: '/dashboard',
          classes: 'nav-item',
          icon: 'feather icon-activity'
        },
      ]
    },

    {
      id: 'dam',
      title: 'Digital Asset Management',
      type: 'group',
      icon: 'icon-support',
      children: [
        {
          id: 'file-manager',
          title: 'File Manager',
          type: 'item',
          url: '/file-manager',
          classes: 'nav-item',
          icon: 'feather icon-folder'
        },
        {
          id: 's3-api-config',
          title: 'S3 API Config',
          type: 'item',
          url: '/s3-api-config',
          classes: 'nav-item',
          icon: 'feather icon-sliders'
        },
        {
          id: 'shared-links',
          title: 'Shared Links',
          type: 'item',
          url: '/shared-links',
          classes: 'nav-item',
          icon: 'feather icon-link'
        },
      ]
    },

    {
      id: 'security',
      title: 'Security',
      type: 'group',
      icon: 'icon-pages',
      children: [
        {
          id: 'auth',
          title: 'Authentication',
          type: 'collapse',
          icon: 'feather icon-lock',
          children: [
            {
              id: 'reset-password-1',
              title: 'Reset Password',
              type: 'item',
              url: '/auth/reset-password-1',
              target: true,
              breadcrumbs: false
            },
            {
              id: 'change-password',
              title: 'Change Password',
              type: 'item',
              url: '/auth/change-password',
              target: true,
              breadcrumbs: false
            },
            {
              id: 'profile-settings',
              title: 'Profile Settings',
              type: 'item',
              url: '/auth/profile-settings',
              target: true,
              breadcrumbs: false
            }
          ]
        },
      ]
    },
    {
      id: 'support',
      title: 'Support',
      type: 'group',
      icon: 'icon-support',
      children: [
        {
          id: 'account-manager',
          title: 'Account Manager',
          type: 'item',
          url: '/account-manager',
          classes: 'nav-item',
          icon: 'feather icon-phone'
        },
        {
          id: 'documentation',
          title: 'Documentation',
          type: 'item',
          url: '/documentation',
          classes: 'nav-item',
          icon: 'feather icon-book',
          // target: true,
          // external: true
        }
      ]
    }
  ]
};

export default menuItems;
